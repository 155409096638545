import React, { useEffect, useState } from 'react';
import './App.css';
import Recipe from './Recipe';

const App = () => {
	const APP_ID = '12a11a58';
	const API_KEY = 'a46c0717b750ad84c8fc178854ed0866';

	// const example_req = `https://api.edamam.com/search?q=chicken&app_id=${APP_ID}&app_key=${API_KEY}`;

	const [recipes, setRecipes] = useState([]);
	const [search, setSearch] = useState('');
	const [query, setQuery] = useState('chicken');

	useEffect(() => {
		getRecipes();
		// eslint-disable-next-line
	}, [query]);

	const getRecipes = async () => {
		const response = await fetch(`https://api.edamam.com/search?q=${query}&app_id=${APP_ID}&app_key=${API_KEY}`);
		const data = await response.json();
		setRecipes(data.hits);
		console.log(data.hits);
	};

	const updateSearch = e => {
		setSearch(e.target.value);
	};

	const getSearch = e => {
		e.preventDefault();
		setQuery(search);
		setSearch('');
	};

	return (
		<div className="App">
			<form className="search-form" onSubmit={getSearch}>
				<input className="search-bar" type="text" value={search} onChange={updateSearch} />
				<button type="submit" className="search-button">
					Search
				</button>
			</form>
			<div className="recipes">
				{recipes.map(recipe => (
					<Recipe key={recipe.recipe.label} title={recipe.recipe.label} calories={recipe.recipe.calories} image={recipe.recipe.image} ingredients={recipe.recipe.ingredients} />
				))}
			</div>
		</div>
	);
};

export default App;
